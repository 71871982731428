#custom-calendar .css-epd502 {
    width: 100% !important;
}
#custom-calendar .css-mvmu1r {
    width: 100% !important;
    justify-content: space-between !important
}

#custom-calendar .MuiCalendarPicker-root .css-169iwlq-MuiCalendarPicker-root {
    justify-content: space-between !important;
    width: 100% !important;
}

#custom-calendar .css-1dozdou {
    padding-left: 16px !important;
    width: 100% !important;
    justify-content: space-between !important
}

#custom-calendar .css-1n2mv2k {
    justify-content: space-between !important
}
#custom-calendar .MuiCalendarPicker-root {
    width: 100% !important;
    padding-inline: 16px;
  }

#custom-calendar .PrivatePickersFadeTransitionGroup-root .MuiCalendarPicker-viewTransitionContainer .css-1wvgxus-MuiCalendarPicker-viewTransitionContainer {
    width: 100% !important;
    justify-content: space-between !important
}


#custom-calendar .date-selection {
    padding: 3%;
    position: fixed;
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    transition: right 0.3s ease-in-out;
    z-index: 5;

    display: flex;
    flex-direction: column;
}
  
#custom-calendar .date-selection.open {
    right: 0;
}

#custom-calendar .date-selection-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10% 0;
    height: 100%;
}

#custom-calendar .date-selection-container .date-selection-content {
    width: 100%;
    height: fit-content;
}

#custom-calendar .date-selection-container .highlighted {
    width: 80%;
    color: var(--boka-color-low);
    display: flex;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 266%;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: start;
    padding: 0 3%;
}

#custom-calendar .date-selection-container .selected-date {
    width: 100%;
    display: flex;
    color: var(--light-background);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 133.4%;
    letter-spacing: 0.25px;
    text-align: start;
    padding: 0 3%;
}

#custom-calendar div.calendar {
    height: 100%;
}

#custom-calendar button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
  color: var(--light-background);
}

*:focus:not(.focus-visible) {
    outline: none;
}

#custom-calendar button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.Mui-disabled {
    color: var(--subtitle-color);
  }

#custom-calendar div.calendar button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.Mui-selected, div.calendar button.MuiPickersMonth-monthButton.Mui-selected {
    color: var(--background-color);
    background-color: var(--boka-color);
}

#custom-calendar button.MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-switchViewButton {
    color: var(--subtitle-color)
}

#custom-calendar div.MuiPickersArrowSwitcher-root button {
    color: var(--subtitle-color)
}

#custom-calendar div.MuiDayCalendar-header > span{
    color: var(--subtitle-color)
}

#custom-calendar .date-selection-container .button-section {
    width: 100%;
    display: flex;
    justify-content: center;
}

#custom-calendar .date-selection-container .button-section .boka-button {
    width: 90%;
}

#custom-calendar div.calendar div.MuiDateCalendar-root {
    height: 100%;
    max-height: 100%;
    width: 100%;
}

#custom-calendar .MuiPickersFadeTransitionGroup-root.MuiDateCalendar-viewTransitionContainer > * {
    height: 100%;
}

#custom-calendar .MuiPickersFadeTransitionGroup-root.MuiDateCalendar-viewTransitionContainer > * > * {
    height: 100%;
}

#custom-calendar .MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel{
    width: 14vw;
    height: 14vw;
}

#custom-calendar .MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    width: 13vw;
    height: 13vw;
}

#custom-calendar .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition {
    height: 100%;
    max-height: 100%;
}